import {mapMutations} from "vuex";

//sections
export default {
    name: "order-product",
    components: {},
    props: {
        item: {
            type: Object,
            default: () => {
            }
        }
    },
    data() {
        return {}
    },
    created() {
    },
    computed: {},
    mounted() {
    },
    watch: {},
    methods: {
        ...mapMutations({})
    }
}
