import {mapMutations, mapGetters, mapActions} from "vuex";

//sections
import SmallLoader from "@/components/small-loader/index.vue";
import autocomplete from "@/components/autocomplete/index.vue";
import {validation} from "@/mixins/validation";

export default {
    name: "login-first-step",
    components: {
        SmallLoader,
        autocomplete
    },
    props: {
        errorName: {
            type: Boolean,
            default: false
        },
        errorSurname: {
            type: Boolean,
            default: false
        },
        errorPhone: {
            type: Boolean,
            default: false
        },
        errorTown: {
            type: Boolean,
            default: false
        },
        errorDepartment: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            selectedWarRef: '',
            selectedCityRef: '',
            selectedAddressRef: '',
            courier: {
                id: 0,
                label: this.$t('personalOffice.orders.department'),
            },
            courierAddress: {
                city: '',
                address: '',
                home: ''
            },
            otherRecipient: false,
            deliveryType: '',
            recipientName: '',
            recipientSurName: '',
            recipientPhone: '',
            npDelivery: {
                city: '',
                department: ''
            },
            satDelivery: {
                city: '',
                department: ''
            },
            delivery: {
                city: '',
                cityId: '',
                regionId: '',
                department: ''
            },
            npDeliveryType: [
                {
                    id: 0,
                    label: this.$t('personalOffice.orders.department'),
                },
                {
                    id: 1,
                    label: this.$t('personalOffice.orders.courier'),
                }
            ],
            regions: [],
            departmentsList: []
        }
    },
    watch: {
        'courierAddress.city': function () {
            if (this.$refs.novapochtaTownCourier) {
                this.$refs.novapochtaTownCourier.textContent = ''
            }
            if (this.$refs.catTownCourier) {
                this.$refs.catTownCourier.textContent = ''
            }
            if (this.$refs.deliveryTownCourier) {
                this.$refs.deliveryTownCourier.textContent = ''
            }
        },
        'courierAddress.address': function () {
            if (this.$refs.novapochtaAddressCourier) {
                this.$refs.novapochtaAddressCourier.textContent = ''
            }
            if (this.$refs.catAddressCourier) {
                this.$refs.catAddressCourier.textContent = ''
            }
            if (this.$refs.deliveryAddressCourier) {
                this.$refs.deliveryAddressCourier.textContent = ''
            }
        },
        'courierAddress.home': function () {
            if (this.$refs.novapochtaHouseCourier) {
                this.$refs.novapochtaHouseCourier.textContent = ''
            }
            if (this.$refs.catHouseCourier) {
                this.$refs.catHouseCourier.textContent = ''
            }
            if (this.$refs.deliveryHouseCourier) {
                this.$refs.deliveryHouseCourier.textContent = ''
            }
        },
        'deliveryType.key': function () {
            this.selectedWarRef = ''
            this.selectedCityRef = ''
            this.selectedAddressRef = ''
            this.courier = {id: 0, label: this.$t('personalOffice.orders.department')}
            this.$refs.emptyDeliveryMethod.textContent = ''
            let obj = {}
            obj.checked = false
            this.setDeliveries(obj)
        },
        'npDelivery.department': function () {
            this.$refs.novapochtaDeliveryError.textContent = ''
        },
        'npDelivery.city': function () {
            this.$refs.novapochtaTownError.textContent = ''
        },
        'satDelivery.department': function () {
            this.$refs.satDepartmentError.textContent = ''
        },
        'satDelivery.city': function () {
            this.$refs.satCityError.textContent = ''
        },
        'delivery.department': function () {
            this.$refs.deliveryDepartmentError.textContent = ''
        },
        'delivery.city': function () {
            this.$refs.deliveryCityError.textContent = ''
        },
        'courier': function () {
            this.selectedWarRef = ''
            this.selectedCityRef = ''
            this.selectedAddressRef = ''
            this.satDelivery.city = ''
            this.satDelivery.department = ''
            this.npDelivery.city = ''
            this.npDelivery.depadeliveryrtment = ''
            this.delivery.area = ''
            this.delivery.city = ''
            this.delivery.department = ''
            this.courierAddress = {
                city: '',
                address: '',
                home: ''
            }
            let obj = {}
            obj.checked = false
            this.setDeliveries(obj)
        },

        deliveryType() {
            this.resetDeliveryList('addressesList')
            this.resetDeliveryList('departmentsList')
            this.resetDeliveryList('citiesList')
            if (this.courier.id === 1) {
                this.npDelivery.city = ''
                this.npDelivery.department = ''
            }
            if (this.courier.id === 0) {
                this.courierAddress.city = ''
                this.npDelivery.address = ''
                this.npDelivery.home = ''
            }
            let obj = {}
            obj.checked = false
            this.setDeliveries(obj)
            switch (this.deliveryType.key) {
                case  'nova_poshta':
                    this.$refs.emptyDeliveryMethod.textContent = ''
                    this.satDelivery.city = ''
                    this.satDelivery.department = ''
                    this.delivery.area = ''
                    this.delivery.city = ''
                    this.delivery.department = ''
                    break;

                case 'cat':
                    this.$refs.emptyDeliveryMethod.textContent = ''
                    this.npDelivery.city = ''
                    this.npDelivery.depadeliveryrtment = ''
                    this.delivery.area = ''
                    this.delivery.city = ''
                    this.delivery.department = ''
                    break;

                case 'delivery':
                    this.$refs.emptyDeliveryMethod.textContent = ''
                    this.npDelivery.city = ''
                    this.npDelivery.department = ''
                    this.satDelivery.city = ''
                    this.satDelivery.department = ''
                    break;
            }
        },
        recipientName() {
            this.setRecipientData({
                name: this.recipientName,
                surname: this.recipientSurName,
                phone: this.recipientPhone.replace(/[^\d]/g, '')
            })
            if (this.recipientName.length > 0) {
                this.$refs.recipientNameError.textContent = ''
            }
        },
        recipientSurName() {
            this.setRecipientData({
                name: this.recipientName,
                surname: this.recipientSurName,
                phone: this.recipientPhone.replace(/[^\d]/g, '')
            })
            if (this.recipientSurName.length > 0) {
                this.$refs.recipientSurNameError.textContent = ''
            }
        },
        recipientPhone() {
            this.setRecipientData({
                name: this.recipientName,
                surname: this.recipientSurName,
                phone: this.recipientPhone.replace(/[^\d]/g, '')
            })
            if (this.recipientPhone.length > 0) {
                this.$refs.recipientPhoneError.textContent = ''
            }
        }
    },
    created() {
        this.getDeliveryList()
    },
    mounted() {
        this.$root.$on('sendDeliveryData', () => {

        })
    },
    computed: {
        ...mapGetters({
            getRecipientData: 'order/otherRecipientData',
            towns: 'order/townsList',
            departments: 'order/departmentList',
            satCities: 'order/catTowns',
            satDepartments: 'order/catDepartments',
            deliveryCities: 'order/deliveryTowns',
            deliveryDepartments: 'order/deliveryDepartments',
            deliveryList: 'order/deliveriesList',
            citiesList: 'order/citiesList',
            basket: 'basket/basketCount',
            departmentList: 'order/departmentsList',
            addressesList: 'order/addressesList',
            npTownsLoading: 'order/npTownsLoading',
            npDepartmentsLoading: 'order/npDepartmentsLoading',
            addressListLoading: 'order/addressListLoading'
        })
    },
    methods: {
        ...mapMutations({
            setRecipientData: 'order/SET_DATA_OTHER_RECIPIENT',
            setDepartments: 'order/NOVA_POSHTA_SET_DEPARTMENS',
            setDeliveries: 'order/SELECT_DELIVERY',
            resetDeliveryList: 'order/RESET_DELIVERY_LIST'
        }),
        ...mapActions({
            fetchTowns: 'order/NOVA_POSHTA_GET_TOWNS',
            fetchDepartments: 'order/NOVA_POSHTA_GET_DEPARTMENS',
            fetchSatTowns: 'order/CAT_GET_TOWNS',
            fetchSatDepartments: 'order/CAT_GET_DEPARTMENS',
            fetchDeliveryTowns: 'order/DELIVERY_GET_TOWNS',
            fetchDeliveryDepartments: 'order/DELIVERY_GET_DEPARTMENS',
            getDeliveryList: 'order/GET_DELIVERIES_LIST',
            getTownsList: 'order/GET_TOWNS_LIST',
            getDepartmentsList: 'order/GET_DEPARTMENTS_LIST',
            getAddressesList: 'order/GET_ADDRESS_LIST'
        }),
        requestTown(data, service) {
            this.selectedCityRef = ''
            if (data.length >= 3) {
                this.getTownsList({
                    'city_name': data,
                    'service': service
                })
            }
        },
        requestAddresses(type) {
            this.selectedAddressRef = ''
            if (this.courierAddress.address.length >= 3) {
                this.getAddressesList({
                    'city_name': this.courierAddress.city,
                    'service': type,
                    'city_ref': this.selectedCityRef,
                    'order': this.basket.basket.id,
                    'street_name': this.courierAddress.address
                })
            }
        },
        findDelCity() {
            setTimeout(() => {
                let city = this.citiesList.find(el => el.description === this.npDelivery.city)
                if (city) {
                    this.selectedCityRef = city.ref
                    this.getDepartmentsList({
                        'city_name': this.npDelivery.city,
                        'service': 'nova_poshta',
                        'city_ref': this.selectedCityRef,
                        'order': this.basket.basket.id
                    })
                }

            }, 500)

        },
        findCatCity() {
            setTimeout(() => {
                let city = this.citiesList.find(el => el.description === this.satDelivery.city)
                if (city) {
                    this.selectedCityRef = city.ref
                    this.getDepartmentsList({
                        'city_name': this.satDelivery.city,
                        'service': 'cat',
                        'city_ref': this.selectedCityRef,
                        'order': this.basket.basket.id
                    })
                }

            }, 500)

        },
        findDeliveryCity() {
            setTimeout(() => {
                let city = this.citiesList.find(el => el.description === this.delivery.city)

                if (city) {
                    this.selectedCityRef = city.ref
                    this.getDepartmentsList({
                        'city_name': this.delivery.city,
                        'service': 'delivery',
                        'city_ref': this.selectedCityRef,
                        'order': this.basket.basket.id
                    })
                }

            }, 500)

        },
        findRefCity() {
            setTimeout(() => {
                let city = this.citiesList.find(el => el.description ? el.description : el.name === this.courierAddress.city)
                if (city) {
                    this.selectedCityRef = city.ref

                    this.getDepartmentsList({
                        'city_name': this.courierAddress.city,
                        'service': 'nova_poshta',
                        'city_ref': this.selectedCityRef,
                        'order': this.basket.basket.id
                    })
                }

            }, 500)

        },
        findRefStreet() {
            setTimeout(() => {
                let street = this.addressesList.find(el => el.description === this.courierAddress.address)
                if (street) {
                    street.ref ? this.selectedAddressRef = street.ref : this.selectedAddressRef = '63422722-4172-11dd-9198-001d60451983'
                }

            }, 500)
        },
        requestDepartments() {
            this.getDepartmentsList({
                'city_name': this.npDelivery.city,
                'service': 'nova_poshta',
                'city_ref': this.selectedCityRef,
                'order': this.basket.basket.id
            })
        },
        requestCatDepartments() {
            this.getDepartmentsList({
                'city_name': this.satDelivery.city,
                'service': 'cat',
                'city_ref': this.selectedCityRef,
                'order': this.basket.basket.id
            })
        },
        requestDeliveryDepartments() {
            this.getDepartmentsList({
                'city_name': this.delivery.city,
                'service': 'delivery',
                'city_ref': this.selectedCityRef,
                'order': this.basket.basket.id
            })
        },
        validationDeliveryMethod() {
            let error = this.$refs.emptyDeliveryMethod
            let deliveryType = this.deliveryType
            if (error) {
                error.textContent = ''
            }
            switch (true) {
                case deliveryType.length === 0:
                    error.append(this.$t('basket.chooseDeliveryType'));
                    break;
            }
        },
        sendData() {
            let obj = {}
            switch (true) {
                case !this.deliveryType.key:
                    this.validationDeliveryMethod()
                    break;

                case this.deliveryType.key === 'nova_poshta' && this.otherRecipient === true && this.courier.id === 0:
                    validation.methods.validation(this.$refs.recipientNameError, this.recipientName, 2)
                    validation.methods.validation(this.$refs.recipientSurNameError, this.recipientSurName, 2)
                    validation.methods.validation(this.$refs.recipientPhoneError, this.recipientPhone, 19)
                    validation.methods.validation(this.$refs.novapochtaTownError, this.npDelivery.city)
                    validation.methods.validation(this.$refs.novapochtaDeliveryError, this.npDelivery.department)
                    !this.selectedCityRef ? this.$refs.novapochtaTownError.innerHTML = this.$t('personalOffice.orders.cityError') : this.$refs.novapochtaTownError.innerHTML = ''
                    obj.self_pickup = false
                    obj.delivery_id = this.deliveryType.id

                    if (this.npDelivery.city && this.npDelivery.department && this.recipientName && this.recipientSurName && this.recipientPhone && this.selectedAddressRef) {
                        let warehouses = this.departmentList.find(el => el.description === this.npDelivery.department)
                        obj.checked = true
                        obj.city_ref = this.selectedCityRef
                        obj.to_flat = false
                        obj.warehouse_ref = warehouses.ref
                        obj.city = this.npDelivery.city
                        obj.number = this.npDelivery.department
                        obj.another_recipient = true
                        obj.name = this.recipientName
                        obj.middle_name = this.recipientSurName
                        obj.phone = this.recipientPhone
                        this.setDeliveries(obj)
                    }
                    break;
                case this.deliveryType.key === 'nova_poshta' && this.otherRecipient === true && this.courier.id === 1:
                    validation.methods.validation(this.$refs.recipientNameError, this.recipientName, 2)
                    validation.methods.validation(this.$refs.recipientSurNameError, this.recipientSurName, 2)
                    validation.methods.validation(this.$refs.recipientPhoneError, this.recipientPhone, 19)
                    validation.methods.validation(this.$refs.novapochtaTownCourier, this.courierAddress.city)
                    validation.methods.validation(this.$refs.novapochtaAddressCourier, this.courierAddress.address)
                    validation.methods.validationAddress(this.$refs.novapochtaHouseCourier, this.courierAddress.home, 1)
                    !this.selectedCityRef ? this.$refs.novapochtaTownCourier.innerHTML = this.$t('personalOffice.orders.cityError') : this.$refs.novapochtaTownCourier.innerHTML = ''
                    !this.selectedAddressRef ? this.$refs.novapochtaAddressCourier.innerHTML = this.$t('personalOffice.orders.streetError') : this.$refs.novapochtaAddressCourier.innerHTML = ''
                    obj.self_pickup = false
                    obj.delivery_id = this.deliveryType.id

                    if (this.courierAddress.city && this.courierAddress.address && this.courierAddress.home.length >= 1 && this.selectedCityRef && this.recipientName && this.recipientSurName && this.recipientPhone && this.selectedAddressRef) {

                        let addressRef = this.addressesList.find(el => el.description === this.courierAddress.address)

                        obj.checked = true
                        obj.building_number = this.courierAddress.home
                        obj.street_name = this.courierAddress.address
                        obj.street_ref = addressRef.ref
                        obj.city_ref = this.selectedCityRef
                        obj.to_flat = true
                        obj.city = this.courierAddress.city
                        obj.number = '0'
                        obj.another_recipient = true
                        obj.name = this.recipientName
                        obj.middle_name = this.recipientSurName
                        obj.phone = this.recipientPhone
                        this.setDeliveries(obj)
                    }
                    break;

                case this.deliveryType.key === 'nova_poshta' && this.otherRecipient === false && this.courier.id === 1:
                    validation.methods.validation(this.$refs.novapochtaTownCourier, this.courierAddress.city)
                    validation.methods.validation(this.$refs.novapochtaAddressCourier, this.courierAddress.address)
                    validation.methods.validationAddress(this.$refs.novapochtaHouseCourier, this.courierAddress.home, 1)
                    !this.selectedCityRef ? this.$refs.novapochtaTownCourier.innerHTML = this.$t('personalOffice.orders.cityError') : this.$refs.novapochtaTownCourier.innerHTML = ''
                    !this.selectedAddressRef ? this.$refs.novapochtaAddressCourier.innerHTML = this.$t('personalOffice.orders.streetError') : this.$refs.novapochtaAddressCourier.innerHTML = ''
                    obj.self_pickup = false
                    obj.delivery_id = this.deliveryType.id

                    if (this.courierAddress.city && this.courierAddress.address && this.courierAddress.home.length >= 1 && this.selectedCityRef && this.selectedAddressRef) {
                        let addressRef = this.addressesList.find(el => el.description === this.courierAddress.address)
                        obj.checked = true
                        obj.building_number = this.courierAddress.home
                        obj.street_name = this.courierAddress.address
                        obj.street_ref = addressRef.ref
                        obj.city_ref = this.selectedCityRef
                        obj.to_flat = true
                        obj.city = this.courierAddress.city
                        obj.another_recipient = false
                        obj.number = this.npDelivery.department
                        this.setDeliveries(obj)
                    }
                    break;
                case this.deliveryType.key === 'nova_poshta' && this.otherRecipient === false && this.courier.id === 0:
                    validation.methods.validation(this.$refs.novapochtaTownError, this.npDelivery.city)
                    validation.methods.validation(this.$refs.novapochtaDeliveryError, this.npDelivery.department)
                    !this.selectedCityRef ? this.$refs.novapochtaTownError.innerHTML = this.$t('personalOffice.orders.cityError') : this.$refs.novapochtaTownError.innerHTML = ''
                    obj.self_pickup = false
                    obj.delivery_id = this.deliveryType.id

                    if (this.npDelivery.city && this.npDelivery.department && this.selectedCityRef) {
                        let warehouses = this.departmentList.find(el => el.description === this.npDelivery.department)
                        obj.checked = true
                        obj.warehouse_ref = warehouses.ref
                        obj.city_ref = this.selectedCityRef
                        obj.to_flat = false
                        obj.city = this.npDelivery.city
                        obj.number = this.npDelivery.department
                        obj.another_recipient = false
                        this.setDeliveries(obj)
                    }
                    break;


                case this.deliveryType.key === 'cat' && this.otherRecipient === true && this.courier.id === 0:
                    validation.methods.validation(this.$refs.recipientNameError, this.recipientName, 2)
                    validation.methods.validation(this.$refs.recipientSurNameError, this.recipientSurName, 2)
                    validation.methods.validation(this.$refs.recipientPhoneError, this.recipientPhone, 19)
                    validation.methods.validation(this.$refs.satCityError, this.satDelivery.city)
                    validation.methods.validation(this.$refs.satDepartmentError, this.satDelivery.department)
                    !this.selectedCityRef ? this.$refs.satCityError.innerHTML = this.$t('personalOffice.orders.cityError') : this.$refs.satCityError.innerHTML = ''
                    obj.self_pickup = false
                    obj.delivery_id = this.deliveryType.id

                    if (this.satDelivery.city && this.satDelivery.department && this.recipientName && this.recipientSurName && this.recipientPhone && this.selectedAddressRef) {
                        let warehouses = this.departmentList.find(el => el.description === this.satDelivery.department)
                        obj.checked = true
                        obj.city_ref = this.selectedCityRef
                        obj.to_flat = false
                        obj.warehouse_ref = warehouses.ref
                        obj.city = this.satDelivery.city
                        obj.number = this.satDelivery.department
                        obj.another_recipient = true
                        obj.name = this.recipientName
                        obj.middle_name = this.recipientSurName
                        obj.phone = this.recipientPhone
                        this.setDeliveries(obj)
                    }
                    break;
                case this.deliveryType.key === 'cat' && this.otherRecipient === true && this.courier.id === 1:
                    validation.methods.validation(this.$refs.recipientNameError, this.recipientName, 2)
                    validation.methods.validation(this.$refs.recipientSurNameError, this.recipientSurName, 2)
                    validation.methods.validation(this.$refs.recipientPhoneError, this.recipientPhone, 19)
                    validation.methods.validation(this.$refs.catTownCourier, this.courierAddress.city)
                    validation.methods.validation(this.$refs.catAddressCourier, this.courierAddress.address)
                    validation.methods.validationAddress(this.$refs.catHouseCourier, this.courierAddress.home, 1)
                    !this.selectedCityRef ? this.$refs.catTownCourier.innerHTML = this.$t('personalOffice.orders.cityError') : this.$refs.catTownCourier.innerHTML = ''
                    !this.selectedAddressRef ? this.$refs.catAddressCourier.innerHTML = this.$t('personalOffice.orders.streetError') : this.$refs.catAddressCourier.innerHTML = ''
                    obj.self_pickup = false
                    obj.delivery_id = this.deliveryType.id

                    if (this.courierAddress.city && this.courierAddress.address && this.courierAddress.home.length >= 1 && this.selectedCityRef && this.recipientName && this.recipientSurName && this.recipientPhone && this.selectedAddressRef) {
                        let addressRef = this.addressesList.find(el => el.description === this.courierAddress.address)
                        obj.checked = true
                        obj.building_number = this.courierAddress.home
                        obj.street_name = this.courierAddress.address
                        obj.street_ref = addressRef.ref
                        obj.city_ref = this.selectedCityRef
                        obj.to_flat = true
                        obj.city = this.courierAddress.city
                        obj.number = '0'
                        obj.another_recipient = true
                        obj.name = this.recipientName
                        obj.middle_name = this.recipientSurName
                        obj.phone = this.recipientPhone
                        this.setDeliveries(obj)
                    }
                    break;

                case this.deliveryType.key === 'cat' && this.otherRecipient === false && this.courier.id === 1:
                    validation.methods.validation(this.$refs.catTownCourier, this.courierAddress.city)
                    validation.methods.validation(this.$refs.catAddressCourier, this.courierAddress.address)
                    validation.methods.validationAddress(this.$refs.catHouseCourier, this.courierAddress.home, 1)
                    !this.selectedCityRef ? this.$refs.catTownCourier.innerHTML = this.$t('personalOffice.orders.cityError') : this.$refs.catTownCourier.innerHTML = ''
                    !this.selectedAddressRef ? this.$refs.catAddressCourier.innerHTML = this.$t('personalOffice.orders.streetError') : this.$refs.catAddressCourier.innerHTML = ''
                    obj.self_pickup = false
                    obj.delivery_id = this.deliveryType.id

                    if (this.courierAddress.city && this.courierAddress.address && this.courierAddress.home.length >= 1 && this.selectedCityRef && this.selectedAddressRef) {
                        let addressRef = this.addressesList.find(el => el.description === this.courierAddress.address)
                        obj.checked = true
                        obj.building_number = this.courierAddress.home
                        obj.street_name = this.courierAddress.address
                        obj.street_ref = addressRef.ref
                        obj.city_ref = this.selectedCityRef
                        obj.to_flat = true
                        obj.city = this.courierAddress.city
                        obj.another_recipient = false
                        // obj.number = this.npDelivery.department
                        this.setDeliveries(obj)
                    }
                    break;
                case this.deliveryType.key === 'cat' && this.otherRecipient === false && this.courier.id === 0:
                    validation.methods.validation(this.$refs.satCityError, this.satDelivery.city)
                    validation.methods.validation(this.$refs.satDepartmentError, this.satDelivery.department)
                    !this.selectedCityRef ? this.$refs.satCityError.innerHTML = this.$t('personalOffice.orders.cityError') : this.$refs.satCityError.innerHTML = ''
                    obj.self_pickup = false
                    obj.delivery_id = this.deliveryType.id

                    if (this.satDelivery.city && this.satDelivery.department && this.selectedCityRef) {
                        let warehouses = this.departmentList.find(el => el.description === this.satDelivery.department)
                        obj.checked = true
                        obj.warehouse_ref = warehouses.ref
                        obj.city_ref = this.selectedCityRef
                        obj.to_flat = false
                        obj.city = this.satDelivery.city
                        obj.number = this.satDelivery.department
                        obj.another_recipient = false
                        this.setDeliveries(obj)
                    }
                    break;

                case this.deliveryType.key === 'delivery' && this.otherRecipient === true && this.courier.id === 0:
                    validation.methods.validation(this.$refs.recipientNameError, this.recipientName, 2)
                    validation.methods.validation(this.$refs.recipientSurNameError, this.recipientSurName, 2)
                    validation.methods.validation(this.$refs.recipientPhoneError, this.recipientPhone, 19)
                    validation.methods.validation(this.$refs.deliveryCityError, this.delivery.city)
                    validation.methods.validation(this.$refs.deliveryDepartmentError, this.delivery.department)
                    !this.selectedCityRef ? this.$refs.deliveryCityError.innerHTML = this.$t('personalOffice.orders.cityError') : this.$refs.deliveryTownError.innerHTML = ''
                    obj.self_pickup = false
                    obj.delivery_id = this.deliveryType.id

                    if (this.delivery.city && this.delivery.department && this.recipientName && this.recipientSurName && this.recipientPhone && this.selectedAddressRef) {
                        let warehouses = this.departmentList.find(el => el.description === this.delivery.department)
                        obj.checked = true
                        obj.city_ref = this.selectedCityRef
                        obj.to_flat = false
                        warehouses.ref ? obj.warehouse_ref = warehouses.ref : obj.warehouse_ref = warehouses.id
                        obj.city = this.delivery.city
                        obj.number = this.delivery.department
                        obj.another_recipient = true
                        obj.name = this.recipientName
                        obj.middle_name = this.recipientSurName
                        obj.phone = this.recipientPhone
                        this.setDeliveries(obj)
                    }
                    break;
                case this.deliveryType.key === 'delivery' && this.otherRecipient === true && this.courier.id === 1:
                    validation.methods.validation(this.$refs.recipientNameError, this.recipientName, 2)
                    validation.methods.validation(this.$refs.recipientSurNameError, this.recipientSurName, 2)
                    validation.methods.validation(this.$refs.recipientPhoneError, this.recipientPhone, 19)
                    validation.methods.validation(this.$refs.deliveryTownCourier, this.courierAddress.city)
                    validation.methods.validation(this.$refs.deliveryAddressCourier, this.courierAddress.address)
                    validation.methods.validationAddress(this.$refs.deliveryHouseCourier, this.courierAddress.home, 1)
                    !this.selectedCityRef ? this.$refs.deliveryTownCourier.innerHTML = this.$t('personalOffice.orders.cityError') : this.$refs.deliveryTownCourier.innerHTML = ''
                    !this.selectedAddressRef ? this.$refs.deliveryAddressCourier.innerHTML = this.$t('personalOffice.orders.streetError') : this.$refs.deliveryAddressCourier.innerHTML = ''
                    obj.self_pickup = false
                    obj.delivery_id = this.deliveryType.id

                    if (this.courierAddress.city && this.courierAddress.address && this.courierAddress.home.length >= 1 && this.selectedCityRef && this.recipientName && this.recipientSurName && this.recipientPhone && this.selectedAddressRef) {
                        let addressRef = this.addressesList.find(el => el.description === this.courierAddress.address)
                        obj.checked = true
                        obj.building_number = this.courierAddress.home
                        obj.street_name = this.courierAddress.address
                        obj.street_ref = addressRef.ref
                        obj.city_ref = this.selectedCityRef
                        obj.to_flat = true
                        obj.city = this.courierAddress.city
                        obj.number = '0'
                        obj.another_recipient = true
                        obj.name = this.recipientName
                        obj.middle_name = this.recipientSurName
                        obj.phone = this.recipientPhone
                        this.setDeliveries(obj)
                    }
                    break;

                case this.deliveryType.key === 'delivery' && this.otherRecipient === false && this.courier.id === 1:
                    validation.methods.validation(this.$refs.deliveryTownCourier, this.courierAddress.city)
                    validation.methods.validation(this.$refs.deliveryAddressCourier, this.courierAddress.address)
                    validation.methods.validationAddress(this.$refs.deliveryHouseCourier, this.courierAddress.home, 1)
                    !this.selectedCityRef ? this.$refs.deliveryTownCourier.innerHTML = this.$t('personalOffice.orders.cityError') : this.$refs.deliveryTownCourier.innerHTML = ''
                    !this.selectedAddressRef ? this.$refs.deliveryAddressCourier.innerHTML = this.$t('personalOffice.orders.streetError') : this.$refs.deliveryAddressCourier.innerHTML = ''
                    obj.self_pickup = false
                    obj.delivery_id = this.deliveryType.id

                    if (this.courierAddress.city && this.courierAddress.address && this.courierAddress.home.length >= 1 && this.selectedCityRef && this.selectedAddressRef) {
                        let addressRef = this.addressesList.find(el => el.description === this.courierAddress.address)
                        obj.checked = true
                        obj.building_number = this.courierAddress.home
                        obj.street_name = this.courierAddress.address
                        obj.street_ref = addressRef.ref
                        obj.city_ref = this.selectedCityRef
                        obj.to_flat = true
                        obj.city = this.courierAddress.city
                        obj.another_recipient = false
                        // obj.number = this.npDelivery.department
                        this.setDeliveries(obj)
                    }
                    break;
                case this.deliveryType.key === 'delivery' && this.otherRecipient === false && this.courier.id === 0:
                    validation.methods.validation(this.$refs.deliveryCityError, this.delivery.city)
                    validation.methods.validation(this.$refs.deliveryDepartmentError, this.delivery.department)
                    !this.selectedCityRef ? this.$refs.deliveryCityError.innerHTML = this.$t('personalOffice.orders.cityError') : this.$refs.deliveryCityError.innerHTML = ''
                    obj.self_pickup = false
                    obj.delivery_id = this.deliveryType.id

                    if (this.delivery.city && this.delivery.department && this.selectedCityRef) {
                        let warehouses = this.departmentList.find(el => el.description === this.delivery.department)
                        warehouses.ref ? obj.warehouse_ref = warehouses.ref : obj.warehouse_ref = warehouses.id
                        obj.city_ref = this.selectedCityRef
                        obj.to_flat = false
                        obj.city = this.delivery.city
                        obj.number = this.delivery.department
                        obj.another_recipient = false
                        obj.checked = true
                        this.setDeliveries(obj)
                    }
                    break;


                case this.deliveryType.key === 'self_pickup' && this.otherRecipient === true :
                    validation.methods.validation(this.$refs.recipientNameError, this.recipientName, 2)
                    validation.methods.validation(this.$refs.recipientSurNameError, this.recipientSurName, 2)
                    validation.methods.validation(this.$refs.recipientPhoneError, this.recipientPhone, 19)


                    obj.self_pickup = true
                    obj.delivery_id = this.deliveryType.id

                    if (this.recipientName && this.recipientSurName && this.recipientPhone) {
                        obj.checked = true
                        obj.another_recipient = true
                        obj.name = this.recipientName
                        obj.middle_name = this.recipientSurName
                        obj.phone = this.recipientPhone
                        this.setDeliveries(obj)
                    }
                    break;
                case this.deliveryType.key === 'self_pickup' && this.otherRecipient === false :

                    obj.self_pickup = true
                    obj.delivery_id = this.deliveryType.id
                    obj.another_recipient = false
                    obj.checked = true
                    this.setDeliveries(obj)

                    break;
            }
        }
    }
}
